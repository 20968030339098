import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "SubCategory";
const subCategoryService = {
  getSubCategories(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/SubCategories`, model);
    }
  },

  getSubCategoryByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(id, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, id, data);
    }
  },

  delete(id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/${id}`, id);
    }
  },
};

export default subCategoryService;
