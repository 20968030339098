<template>
  <div>
    <div class="page__head d-flex">
      <div
        class="page__head__container ml-auto align-items-center justify-content-between"
      >
        <div class="page__head__text flex-fill"></div>
        <div class="page__head__actions" v-if="userCanCreateEmployer">
          <div class="d-flex">
            <button
              type="button"
              class="mx-2 button page__body__header__button button--grey text--capital"
              data-target="#newEmployerModal"
              data-toggle="modal"
            >
              <span class="d-inline-flex mr-2">new employer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page__body">
      <div class="page__body__header">
        <div
          class="page__head__container align-items-center justify-content-between"
        >
          <div class="page__head__text flex-fill">
            <h1 class="page__head__title">Employers</h1>
          </div>
        </div>
      </div>

      <div class="">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 85%"
              >
                name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in employers" v-bind:key="item.employerKey">
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.name
                }}</span>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="button button--grey text--capital text--small d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editEmployerModal"
                      v-on:click="editEmployer(item.employerKey)"
                      v-if="userCanEditEmployer"
                      >edit</a
                    >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openDeleteConfirmation(item)"
                      v-if="userCanDeleteEmployer"
                      >delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination w-100">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>
            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': paging.pageIndex === num,
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="newEmployerModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="newEmployerModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add an Employer</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEmployerForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerForm.name"
                      v-bind:class="{
                        'input-required': employerFormErr.name,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Category</label>
                    <select
                      class="select select--lg"
                      v-model="employerForm.categoryKey"
                      v-bind:class="{
                        'input-required': employerFormErr.categoryKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select category
                      </option>
                      <option
                        v-for="item in employersCategories"
                        v-bind:key="item.categoryKey"
                        v-bind:value="item.categoryKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Sub category</label>
                    <select
                      class="select select--lg"
                      v-model="employerForm.subcategoryKey"
                      v-bind:class="{
                        'input-required': employerFormErr.subcategoryKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select category
                      </option>
                      <option
                        v-for="item in subCategories"
                        v-bind:key="item.subcategoryKey"
                        v-bind:value="item.subcategoryKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">State</label>
                    <select
                      class="select select--lg"
                      v-model="employerForm.stateKey"
                      v-bind:class="{
                        'input-required': employerFormErr.stateKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select state
                      </option>
                      <option
                        v-for="item in states"
                        v-bind:key="item.stateKey"
                        v-bind:value="item.stateKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Address</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerForm.address"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Landmark</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerForm.landmark"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Phonenumber</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      v-model="employerForm.phonenumber"
                      @input="validatePhoneNumber(employerForm.phonenumber)"
                    />
                    <div class="error" v-if="employerFormErr.phonenumber">
                      <span class="text-danger"
                        >Enter a valid phone number</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Paydate</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      v-model="employerForm.paydate"
                      min="1"
                      max="31"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Employer
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editEmployerModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon" v-show="!isSaving">
              <a
                data-dismiss="modal"
                id="editEmployerModalClose"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit a Employer</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEmployerEditForm">
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Name</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerEditForm.name"
                      v-bind:class="{
                        'input-required': employerEditFormErr.name,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Category</label>
                    <select
                      class="select select--lg"
                      v-model="employerEditForm.categoryKey"
                      v-bind:class="{
                        'input-required': employerEditFormErr.categoryKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select category
                      </option>
                      <option
                        v-for="item in employersCategories"
                        v-bind:key="item.categoryKey"
                        v-bind:value="item.categoryKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Sub category</label>
                    <select
                      class="select select--lg"
                      v-model="employerEditForm.subcategoryKey"
                      v-bind:class="{
                        'input-required': employerEditFormErr.subcategoryKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select category
                      </option>
                      <option
                        v-for="item in subCategories"
                        v-bind:key="item.subcategoryKey"
                        v-bind:value="item.subcategoryKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">State</label>
                    <select
                      class="select select--lg"
                      v-model="employerEditForm.stateKey"
                      v-bind:class="{
                        'input-required': employerEditFormErr.stateKey,
                      }"
                    >
                      <option value="null" disabled selected>
                        Select state
                      </option>
                      <option
                        v-for="item in states"
                        v-bind:key="item.stateKey"
                        v-bind:value="item.stateKey"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Address</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerEditForm.address"
                    />
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Landmark</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerEditForm.landmark"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Phonenumber</label>
                    <input
                      class="input form__input w-100"
                      type="text"
                      v-model="employerEditForm.phonenumber"
                      @input="
                        validateEditPhoneNumber(employerEditForm.phonenumber)
                      "
                    />
                    <div class="error" v-if="employerEditFormErr.phonenumber">
                      <span class="text-danger"
                        >Enter a valid phone number</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form__item">
                  <div class="form-group">
                    <label class="form__label">Paydate</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      v-model="employerEditForm.paydate"
                      min="1"
                      max="31"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Employer
                  </button>

                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewEmployer"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="employerToDelete != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this employer
                  {{ employerToDelete.name }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this employer from your list, you won't be
                  able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteEmployer()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import employerService from "@/core/services/general-setup-service/employer.service";
import employersCategoryService from "@/core/services/general-setup-service/employers-category.service";
import stateService from "@/core/services/general-setup-service/state.service";
import subCategoryService from "@/core/services/general-setup-service/sub-category.service";
import moment from "moment";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default {
  name: "employer",
  components: {
    DeleteModal,
  },
  data: function () {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      subCategories: [],
      states: [],
      employersCategories: [],
      // Employer
      employers: [],
      employerToDelete: null,
      employerForm: {
        name: null,
        categoryKey: null,
        subcategoryKey: null,
        address: null,
        landmark: null,
        phonenumber: null,
        paydate: 0,
        stateKey: null,
      },
      employerFormErr: {
        name: false,
        categoryKey: false,
        subcategoryKey: false,
        stateKey: false,
        phonenumber: false,
      },
      employerEditForm: {
        employerKey: null,
        name: null,
        categoryKey: null,
        subcategoryKey: null,
        address: null,
        landmark: null,
        phonenumber: null,
        paydate: 0,
        stateKey: null,
      },
      employerEditFormErr: {
        employerKey: false,
        name: false,
        categoryKey: false,
        subcategoryKey: false,
        stateKey: false,
        phonenumber: false,
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      fetchModel: {
        pageIndex: 1,
        pageSize: 10,
        name: null,
      },
      // End Employer
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    async getEmployers() {
      this.isLoading = true;
      try {
        const res = await employerService.getEmployers(this.fetchModel);
        this.employers = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getEmployersCategories() {
      this.isLoading = true;
      try {
        const res = await employersCategoryService.getEmployersCategories({
          pageIndex: 1,
          pageSize: 100,
          name: null,
        });
        this.employersCategories = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getStates() {
      this.isLoading = true;
      try {
        const res = await stateService.getStates({
          pageIndex: 1,
          pageSize: 100,
          name: null,
        });
        this.states = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getSubCategories() {
      this.isLoading = true;
      try {
        const res = await subCategoryService.getSubCategories({
          pageIndex: 1,
          pageSize: 100,
          name: null,
        });
        this.subCategories = res.data.data.items;
        this.paging = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    getEmployerByKey(key) {
      try {
        this.isLoading = true;
        employerService.getEmployerByKey(key).then((res) => {
          this.employerEditForm = res.data.data;
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editEmployer(employerKey) {
      try {
        this.clearEmployerEditForm();
        this.getEmployerByKey(employerKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveEmployerForm: async function (e) {
      e.preventDefault();
      if (this.employerFormErr.phonenumber) return;
      if (this.validateEmployerForm()) {
        try {
          this.isSaving = true;
          await employerService.create(this.employerForm);
          this.isSaving = false;
          document.getElementById("newEmployerModalClose").click();
          this.clearEmployerForm();
          this.getEmployers();
          this.alertSuccess("Employer added.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEmployerEditForm: async function (e) {
      e.preventDefault();
      if (this.employerEditFormErr.phonenumber) return;
      if (this.validateEmployerEditForm()) {
        try {
          this.isSaving = true;
          await employerService.update(
            this.employerEditForm.employerKey,
            this.employerEditForm
          );
          this.isSaving = false;
          document.getElementById("editEmployerModalClose").click();
          this.clearEmployerEditForm();
          this.getEmployers();
          this.alertSuccess("Employer updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(state) {
      this.employerToDelete = state;
    },
    async deleteEmployer() {
      if (this.employerToDelete != null) {
        this.isDeleting = true;
        try {
          await employerService.delete(this.employerToDelete.employerKey);
          this.employerToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("Employer deleted");
          this.isDeleting = false;
          this.getEmployers();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },

    validatePhoneNumber(phonenumber) {
      const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      const isValidPhone = regex.test(phonenumber);
      if (isValidPhone) return (this.employerFormErr.phonenumber = false);
      return (this.employerFormErr.phonenumber = true);
    },

    validateEditPhoneNumber(phonenumber) {
      const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      const isValidPhone = regex.test(phonenumber);
      if (isValidPhone) return (this.employerEditFormErr.phonenumber = false);
      return (this.employerEditFormErr.phonenumber = true);
    },

    validateEmployerForm() {
      this.clearEmployerValidation();
      if (
        this.employerForm.name &&
        this.employerForm.categoryKey &&
        this.employerForm.subcategoryKey &&
        this.employerForm.stateKey &&
        this.employerForm.phonenumber
      ) {
        return true;
      }

      for (const key in this.employerFormErr) {
        if (Object.hasOwnProperty.call(this.employerFormErr, key)) {
          if (
            this.employerForm[key] == null ||
            this.employerForm[key] == undefined ||
            this.employerForm[key].trim() == ""
          ) {
            this.employerFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearEmployerForm() {
      this.employerForm = {
        name: null,
        categoryKey: null,
        subcategoryKey: null,
        address: null,
        landmark: null,
        phonenumber: null,
        paydate: 0,
        stateKey: null,
      };
    },
    clearEmployerValidation() {
      this.employerFormErr = {
        name: false,
        categoryKey: false,
        subcategoryKey: false,
        stateKey: false,
        phonenumber: false,
      };
    },

    validateEmployerEditForm() {
      this.clearEmployerEditValidation();
      if (
        this.employerEditForm.name &&
        this.employerEditForm.categoryKey &&
        this.employerEditForm.subcategoryKey &&
        this.employerEditForm.stateKey &&
        this.employerEditForm.phonenumber
      ) {
        return true;
      }

      for (const key in this.employerEditFormErr) {
        if (Object.hasOwnProperty.call(this.employerEditFormErr, key)) {
          if (
            this.employerEditForm[key] == null ||
            this.employerEditForm[key] == undefined ||
            this.employerEditForm[key].trim() == ""
          ) {
            this.employerFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearEmployerEditForm() {
      this.employerFormEdit = {
        employerKey: null,
        name: null,
        categoryKey: null,
        subcategoryKey: null,
        address: null,
        landmark: null,
        phonenumber: null,
        paydate: 0,
        stateKey: null,
      };
    },
    clearEmployerEditValidation() {
      this.employerEditFormErr = {
        employerKey: false,
        name: false,
        categoryKey: false,
        subcategoryKey: false,
        stateKey: false,
        phonenumber: false,
      };
    },

    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      this.getEmployers();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.paging.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.paging.pageIndex - 1;
      }
      this.getEmployers();
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getEmployers();
    this.getEmployersCategories();
    this.getStates();
    this.getSubCategories();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateEmployer() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanCreateEmployer;
    },
    userCanDeleteEmployer() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanDeleteEmployer;
    },
    userCanEditEmployer() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanEditEmployer;
    },
    userCanViewEmployer() {
      const employer = this.user.scopes.find(
        (item) => item.scopeName === Role.EmployerSetup
      );
      const { permissionsList } = employer;
      return permissionsList.CanViewEmployer;
    },
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY : HH:MM");
    },
    formatShortDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY");
    },
  },
};
</script>
